<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CButton @click="collapse=!collapse" color="primary" class="mb-2 mr-2">
                    Filter
                </CButton>
                <CCollapse :show="collapse" :duration="400">
                    <CCard>
                        <CCardHeader>
                            <strong>Filter Options</strong>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.id.val" :description="form.id.inv" label="ID" />
                                </CCol>
                                <CCol sm="3">
                                    <label>User</label>
                                    <model-select :options="users" v-model="form.user_id.val" placeholder="Please Select">
                                    <small class="form-text text-muted w-100">{{form.user_id.inv}}</small>
                                    </model-select>
                                </CCol>
                                <CCol sm="3">
                                    <label>From Date</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.token_validation_from.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.token_validation_from.inv}}</small>
                                </CCol>
                                <CCol sm="3">
                                    <label>To Date</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.token_validate_until.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.token_validate_until.inv}}</small>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label>Created by</label>
                                    <model-select :options="users" v-model="form.created_by.val" placeholder="Please Select">
                                    <small class="form-text text-muted w-100">{{form.created_by.inv}}</small>
                                    </model-select>
                                </CCol>
                                <CCol sm="3">
                                    <label>Created at</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.created_at.val"></date-picker>
                                    <small class="form-text text-muted w-100">{{form.created_at.inv}}</small>
                                </CCol>                                
                            </CRow>
                            <br>
                            <div class="form-group form-actions">
                                <CButton @click="validator();filter=true;page=1;count();get();" color="primary" class="mr-2">Filter</CButton>
                                <CButton @click="remove_filter" color="danger">Remove Filter</CButton>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCollapse>
            </CCol>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Tokens</strong>
                        <div class="card-header-actions">
                            Number of All Rows: {{row_count}}
                        </div>
                        </CCardHeader>
                    <CCardBody>
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }">
                            <template #user_name="data">
                                <td>
                                    <router-link :to="`/user/${data.item.user_id}/details`">{{data.item.user_name}}</router-link>
                                </td>
                            </template>
                             <template #token="data">
                                <td>
                                    <a :href="data.item.token">{{data.item.token}}</a>
                                </td>
                            </template>
                            <template #created_by_name="data">
                                <td>
                                    <router-link :to="`/user/${data.item.created_by}/details`">{{data.item.created_by_name}}</router-link>
                                </td>
                            </template>
                            <template #action="data">
                                <td>
                                    <template>
                                    <div class="flex-container">
                                        <div v-show="$store.state.perm['system.GuestUserTokens'].put"
                                            @click="redirect_to(`/token/${data.item.id}/update`)"
                                            v-c-tooltip.hover="'Update'">
                                        <CIcon height="20" :content="$options.update"/>
                                        </div>
                                        <div v-show="$store.state.perm['system.GuestUserTokens'].delete"
                                            @click="delete_modal=true;id=data.item.id"
                                            v-c-tooltip.hover="'Delete'">
                                        <CIcon height="20" :content="$options.delete"/>
                                        </div>
                                    </div>
                                    </template>
                                </td>
                            </template>
                        </CDataTable>
                            <br>
                            <div> 
                                <CPagination
                                :activePage.sync="page"
                                :pages="page_count"
                                align="center"
                                :doubleArrows="true"
                                />
                            </div>
                        </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            title="Alert"
            size="sm"
            color="danger"
            :show.sync="delete_modal"
            >
            <p>Are you sure?</p>
            <template #footer>
                <CButton @click="delete_modal=false" color="secondary">No</CButton>
                <CButton @click="delete_row" color="danger">Yes</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>
import { ModelSelect } from 'vue-search-select'
import { cilListRich, cilPencil, cilTrash } from '@coreui/icons'
export default {
    components: { ModelSelect },
    details: cilListRich, update: cilPencil, delete: cilTrash,
    data () {
        return {
            row_count: '',
            items: [],
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'token', label: 'Login URL' },
                { key: 'user_name', label: 'User' },
                { key: 'token_validation_from', label: 'From Date' },
                { key: 'token_validate_until', label: 'To Date' },
                { key: 'created_by_name', label: 'Created By' },
                { key: 'created_at', label: 'Created at' },
                { key: 'action', label: 'Action' },
            ],
            collapse: false,
            filter: false,
            form: {
                id: {val: '', inv: '', v: { max: 1024 }},
                user_id: {val: '', inv: '', v: { max: 1024 }},
                token_validation_from: {val: '', inv: '', v: { max: 1024 }},
                token_validate_until: {val: '', inv: '', v: { max: 1024 }},
                created_by: {val: '', inv: '', v: { max: 1024 }},
                created_at: {val: '', inv: '', v: { max: 1024 }},
            },
            users: [],
            disable: false,
            page: 1,
            page_count: 0,
            offset: 0,
            delete_modal: false,
            id: 0,
        }
    },
    watch: {
        page: {
            handler(){
                this.count();
                this.get();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    },
    mounted(){
        this.count();
        this.get();
        this.get_users();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        get_users(){
            this.axios.get('system/users/')
                .then(res => {
                    res.data.map(val => {
                        this.users.push({ value: val.id, text: `${val.id} - ${val.first_name} ${val.last_name}` });
                    });
                })
        },
        count() {
            if(this.disable == false) {
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('system/guest_user_tokens/?count=1' + filter)
                    .then(res => {
                        this.row_count = String(res.data.count).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        if(res.data.count > 100){
                            this.page_count = Math.ceil(res.data.count / 100);
                        } else {
                            this.page_count = 1;
                        }
                    })
            }
        },
        get() {
            if (this.disable == false) {
                this.offset = 0;
                if(this.page != 1){
                    this.offset = (this.page * 100) - 99;
                }
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('system/guest_user_tokens/' 
                    + '?limit=100' 
                    + '&offset=' + this.offset
                    + '&guest_user_info=1'
                    + '&created_by_user_info=1'
                    + filter)
                    .then(res => {
                        res.data.map(val => {
                            val.user_name = `${val.guest_user_info.first_name} ${val.guest_user_info.last_name}`;
                            val.created_by_name = `${val.created_by_user_info.first_name} ${val.created_by_user_info.last_name}`;
                            val.token = `http://159.65.143.185:8080/signin_guest_user/${val.token}`;
                            val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                            val.token_validation_from = this.$moment(val.token_validation_from).format('YYYY-MM-DD HH:mm:ss');
                            val.token_validate_until = this.$moment(val.token_validate_until).format('YYYY-MM-DD HH:mm:ss');
                        });
                        this.items = res.data;
                    })
            }
        },
        delete_row() {
			this.axios.delete('system/guest_user_tokens/'
			+ '?id=' + this.id)
			.then(() => {
				this.$notify({
					group: 'foo',
					type: 'success',
					text: 'Deleted Successfully.'
                });
				this.delete_modal = false;
                this.count();
				this.get();
			})
			.catch(() => {
				this.$notify({
					group: 'foo',
					type: 'error',
					text: 'Error! please try again.'
                });
                this.delete_modal = false;
			})
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        get_badge(status) {
            switch (status) {
                case 'Active': return 'success'
                case 'Inactive': return 'danger'
                default: 'secondary'
            }
        },
        remove_filter() {
            for (const key in this.form) {
                this.form[key].val = '';
                this.form[key].inv = '';
            }
            this.disable = false;
            this.filter = false;
            this.page = 1;
            this.count();
            this.get();
        },
    },
}
</script>
